<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>User Reports</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        :items="userStatus"
        v-model="$store.state.userReports.userReportSelect"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="Status"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="$store.state.userReports.bannedReasonSelect"
        :items="bannedReason"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Banned Reason"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      hide-default-footer
      :items-per-page="1000"
      :options.sync="options"
      :items="reportsData.data"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <div style="width:100px">
          <v-icon class="mr-2" @click="detail(item)">edit</v-icon>
          <v-icon class="mr-2" @click="chatDetail(item)">chat_bubble</v-icon>
        </div>
      </template>

      <template v-slot:item.createdDate="{ item }">
        <div>{{ new Date(item.createdDate * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.userId="{ item }">
        <UserDetailCard
          :userId="item.userId"
          :pictureUrl="item.pictureUrl"
          :userName="item.userName"
        />
      </template>
      <template v-slot:item.fromUserId="{ item }">
        <UserDetailCard
          :userId="item.fromUserId"
          :pictureUrl="item.fromPictureUrl"
          :userName="item.fromUserName"
        />
      </template>
      <template v-slot:item.status="{ item }">
        <div>
          {{ item.status | toUserStatus }}
        </div>
      </template>
      <template v-slot:item.reasons="{ item }">
        <div v-if="item.reasons">
          {{ item.reasons | toBannedReasons }}
        </div>
      </template>
      <template v-slot:item.bannedReason="{ item }">
        <div v-if="item.reasons">
          {{ item.bannedReason | toBannedReason }}
        </div>
      </template>
      <template v-slot:item.room="{ item }">
        <div v-if="item.room">
          {{ item.room.n }}
        </div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="reportsData.totalPage"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="1600px">
        <report-detail-card :userId="userId" :clicked="detailClicked" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-dialog>
      <ChatBannedModalCard :clicked="clicked" :userId="userId" />
    </v-row>
  </div>
</template>
<script>
import userStatus from "@/lib/userStatus";
import bannedReason from "@/lib/bannedReason";
import UserDetailCard from "@/components/Users/UserDetailCard";
import ChatBannedModalCard from "./components/ChatBannedModalCard";
import ReportDetailCard from "./components/ReportDetailCard.vue";
export default {
  components: {
    UserDetailCard,
    ChatBannedModalCard,
    ReportDetailCard,
  },

  data() {
    return {
      dialog: false,
      chatDialog: false,
      userDetail: {},
      isChipDown: false,
      timeleftSelect: null,
      timeleftManuel: null,
      options: {},

      timelefts: [
        { value: "10 Minutes", key: 60 * 10 },
        { value: "1 Hours", key: 60 * 60 },
        { value: "4 Hours", key: 60 * 60 * 4 },
        { value: "12 Hours", key: 60 * 60 * 12 },
        { value: "24 Hours", key: 60 * 60 * 24 },
        { value: "3 Days", key: 60 * 60 * 24 * 3 },
        { value: "7 Days", key: 60 * 60 * 24 * 7 },
        { value: "14 Days", key: 60 * 60 * 24 * 14 },
        { value: "1 Months", key: 60 * 60 * 24 * 30 },
        { value: "3 Months", key: 60 * 60 * 24 * 30 * 3 },
      ],
      bannedReason: bannedReason,
      userStatus: userStatus,
      currentPage: 1,
      user: null,
      reportsData: {},
      userReportSelect: { key: 0, value: "Active" },
      bannedReasonSelect: { key: -1, value: "All" },
      search: "",
      userId: "",
      clicked: false,
      detailClicked: true,
      headers: [
        { text: "From User", value: "fromUserId" },
        { text: "User", value: "userId" },
        { text: "Total Reports", value: "reportCount" },
        { text: "Reasons", value: "reasons" },
        { text: "Banned Reason", value: "bannedReason" },
        { text: "Status", value: "status" },
        { text: "Created Date", value: "createdDate" },
        { text: "Message", value: "message" },
        { text: "Room", value: "room" },
        { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      this.reportsData = await this.$store.dispatch("userReports/getAll", {
        page: this.currentPage - 1,
        search: this.search,
        options: this.options,
      });
    },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
    async detail(item) {
      this.userId = item.userId;
      this.detailClicked = !this.detailClicked;
      this.dialog = true;
    },
    async chatDetail(item) {
      this.userId = item.userId;
      this.clicked = !this.clicked;
    },
  },
  async mounted() {},
};
</script>

<style>
.v-dialog {
  background: #424242 !important;
}
</style>
